import jQuery from "jquery";
import "/src/styles.css";
// **********************************************************************************
//        ARRAYS
// Aray with all postitions in polish

var array_position = [{
    text: "Wybierz z listy",
    value: "Wybierz z listy",
    texteng: "null",
    selected: "Wybierz z listy",
},

{
    "text": "Specjalista ds. Legalizacji",
    "value": "Specjalista ds. Legalizacji",
    "texteng": "Legalisation Specialist"
},
{
    "text": "Koordynator Personelu",
    "value": "Koordynator Personelu",
    "texteng": "Staff Coordinator"
},
{
    "text": "Analityk Czasu Pracy",
    "value": "Analityk Czasu Pracy",
    "texteng": "Working Time Analyst"
},
{
    "text": "Kierownik Działu Operacji UA",
    "value": "Kierownik Działu Operacji UA",
    "texteng": "UA Operations Department Manager"
},
{
    "text": "Specjalista ds. Kadr i Płac",
    "value": "Specjalista ds. Kadr i Płac",
    "texteng": "HR and Payroll Specialist"
},
{
    "text": "Administrator ds. Zakwaterowania",
    "value": "Administrator ds. Zakwaterowania",
    "texteng": "Accommodation Administrator"
},
{
    "text": "Specjalista ds. Analizy i Obsługi Zamówień",
    "value": "Specjalista ds. Analizy i Obsługi Zamówień",
    "texteng": "Order Analysis and Processing Specialist"
},
{
    "text": "Starszy Koordynator Personelu",
    "value": "Starszy Koordynator Personelu",
    "texteng": "Senior Staff Coordinator"
},
{
    "text": "Młodszy Koordynator Personelu",
    "value": "Młodszy Koordynator Personelu",
    "texteng": "Junior Staff Coordinator"
},
{
    "text": "Instruktor Obsługi Wózków Widłowych / Lider Projektu",
    "value": "Instruktor Obsługi Wózków Widłowych / Lider Projektu",
    "texteng": "Forklift Operator Instructor/Project Leader"
},
{
    "text": "Starszy Specjalista ds. Rekrutacji",
    "value": "Starszy Specjalista ds. Rekrutacji",
    "texteng": "Senior Recruitment Specialist"
},
{
    "text": "Młodszy Specjalista ds. Finansów",
    "value": "Młodszy Specjalista ds. Finansów",
    "texteng": "Junior Finance Specialist"
},
{
    "text": "Specjalista ds. Finansów",
    "value": "Specjalista ds. Finansów",
    "texteng": "Finance Specialist"
},
{
    "text": "Specjalista ds. Rekrutacji",
    "value": "Specjalista ds. Rekrutacji",
    "texteng": "Recruitment Specialist"
},
{
    "text": "Młodszy Specjalista ds. Rekrutacji",
    "value": "Młodszy Specjalista ds. Rekrutacji",
    "texteng": "Junior Recruitment Specialist"
},
{
    "text": "Asystentka Biura",
    "value": "Asystentka Biura",
    "texteng": "Office Assistant"
},
{
    "text": "Dyrektor Generalny",
    "value": "Dyrektor Generalny",
    "texteng": "General Director"
},
{
    "text": "Dyrektor Pionu Kapitału Pracownika",
    "value": "Dyrektor Pionu Kapitału Pracownika",
    "texteng": "Head of Human Capital Division"
},
{
    "text": "Dyrektor Pionu Prawnego",
    "value": "Dyrektor Pionu Prawnego",
    "texteng": "Head of Legal Division"
},
{
    "text": "Dyrektor Pionu Finansowego",
    "value": "Dyrektor Pionu Finansowego",
    "texteng": "Head of Finance Division"
},
{
    "text": "Kierownik Działu IT i Innowacji",
    "value": "Kierownik Działu IT i Innowacji",
    "texteng": "IT and Innovation Department Manager"
},
{
    "text": "Kierownik Działu Finansów i Zakupów",
    "value": "Kierownik Działu Finansów i Zakupów",
    "texteng": "Finance and Procurement Manager"
},
{
    "text": "Kierownik Działu Kadr i Płac",
    "value": "Kierownik Działu Kadr i Płac",
    "texteng": "HR and Payroll Department Manager"
},
{
    "text": "Kierownik Działu Sprzedaży",
    "value": "Kierownik Działu Sprzedaży",
    "texteng": "Sales Department Manager"
},
{
    "text": "Starszy Kierownik Działu Operacji",
    "value": "Starszy Kierownik Działu Operacji",
    "texteng": "Senior Operations Department Manager"
},
{
    "text": "Kierownik Działu Rekrutacji UA",
    "value": "Kierownik Działu Rekrutacji UA",
    "texteng": "UA Recruitment Department Manager"
},
{
    "text": "Kierownik Działu Rekrutacji Międzynarodowych",
    "value": "Kierownik Działu Rekrutacji Międzynarodowych",
    "texteng": "International Recruitment Department Manager"
},
{
    "text": "Administrator ds. Nieruchomości i Zakwaterowania",
    "value": "Administrator ds. Nieruchomości i Zakwaterowania",
    "texteng": "Property and Accommodation Administrator"
},
{
    "text": "Starszy Prawnik",
    "value": "Starszy Prawnik",
    "texteng": "Senior Lawyer"
},
{
    "text": "Administrator Biura",
    "value": "Administrator Biura",
    "texteng": "Office Administrator"
},
{
    "text": "Administrator Transportu i Urządzeń Mobilnych",
    "value": "Administrator Transportu i Urządzeń Mobilnych",
    "texteng": "Fleet and Mobile Devices Administrator"
},
{
    "text": "Analityk Baz Danych",
    "value": "Analityk Baz Danych",
    "texteng": "Database Analyst"
},
{
    "text": "Ekspert ds. Kontroli Zewnętrznych",
    "value": "Ekspert ds. Kontroli Zewnętrznych",
    "texteng": "External Audits Expert"
},
{
    "text": "Specjalista ds. Administracji Pracowniczej",
    "value": "Specjalista ds. Administracji Pracowniczej",
    "texteng": "Human Administration Specialist"
},
{
    "text": "Programista",
    "value": "Programista",
    "texteng": "Full Stack Developer"
},
{
    "text": "Starszy Specjalista ds. Realizacji Zamówień",
    "value": "Starszy Specjalista ds. Realizacji Zamówień",
    "texteng": "Senior Order Fulfillment Specialist"
},
{
    "text": "Specjalista ds. Realizacji Zamówień",
    "value": "Specjalista ds. Realizacji Zamówień",
    "texteng": "Order Fulfillment Specialist"
},
{
    "text": "Młodszy Prawnik",
    "value": "Młodszy Prawnik",
    "texteng": "Junior Lawyer"
},
{
    "text": "Prawnik",
    "value": "Prawnik",
    "texteng": "Lawyer"
},
{
    "text": "Młodszy Partner Biznesowy ds. Personalnych",
    "value": "Młodszy Partner Biznesowy ds. Personalnych",
    "texteng": "Junior HR Business Partner"
},
{
    "text": "Młodszy Specjalista ds. Innowacji",
    "value": "Młodszy Specjalista ds. Innowacji",
    "texteng": "Junior Innovation Specialist"
},
{
    "text": "Specjalista ds. IT",
    "value": "Specjalista ds. IT",
    "texteng": "IT Specialist"
},
{
    "text": "Lider Projektu",
    "value": "Lider Projektu",
    "texteng": "Project Lead"
},
{
    "text": "Młodszy Specjalista ds. Kadr i Płac",
    "value": "Młodszy Specjalista ds. Kadr i Płac",
    "texteng": "Junior HR and Payroll Specialist"
},
{
    "text": "Specjalista ds. Archiwizacji",
    "value": "Specjalista ds. Archiwizacji",
    "texteng": "Document Archiving Specialist"
},
{
    "text": "Przedstawiciel Rozwoju Sprzedaży",
    "value": "Przedstawiciel Rozwoju Sprzedaży",
    "texteng": "Sales Development Representative"
}
]


    ;

var arrayChooseSig = [{
    text: "Foreign Osobista",
    value: "sigForeign",
    selected: "1",
},

{
    text: "Foreign Funkcyjna",
    value: "sigForeignFunkcyjna",
},
{
    text: "Foreign Outlook 2016",
    value: "sigForeignO16",
},

{
    text: "Foreign Mac",
    value: "sigForeignOS",
},
{
    text: "Foreign IOS/Android",
    value: "sigForeignMob",
},
{
    text: "GGO",
    value: "sigGGO",
},

{
    text: "GGO Outlook 2016",
    value: "sigGGOo16",
},
{
    text: "GGO Funkcyjna",
    value: "sigGGOFunkcyjna",
},
{
    text: "GGO Mac",
    value: "sigGGOOS",
},

{
    text: "GGO  IOS/Android",
    value: "sigGGOMob",
},
{
    text: "EWWP",
    value: "sigEWWP",
},
{
    text: "EWWP Outlook 2016",
    value: "sigEWWPo16",
},
{
    text: "EWWP Mac",
    value: "sigEWWPOS",
},
{
    text: "EWWP Mobile Android/iOS",
    value: "sigEWWPMob",
},
{
    text: "EWWP Funkcyjna",
    value: "sigEWWPFunkcyjna",
},
{
    text: "Foreign UA Osobista",
    value: "sigForeignUa",
},
{
    text: "Foreign UA Mobile",
    value: "sigForeignUaMob",
},

{
    text: "Foreign Diamond",
    value: "sigForeignDiamond",
},
{
    text: "Kobalt Osobista",
    value: "sigKobaltOsobista",
},
{
    text: "Kobalt Funkcyjna",
    value: "sigKobaltFunkcyjna",
},
{
    text: "Motivated Osobista",
    value: "sigMotivatedOsobista",
},
{
    text: "Motivated Windows",
    value: "sigMotivatedOsobistaWin",
},


];

var arrayChooseSigButton = [{
    text: "Foreign",
    value: "sigForeignButton",
},
{
    text: "Foreign Funkcyjna",
    value: "sigForeignFunkcyjnaButton",
},
{
    text: "Foreign Outlook 2016",
    value: "sigForeignO16Button",
},
{
    text: "Foreign Mac",
    value: "sigForeignOSButton",
},
{
    text: "Foreign  IOS/Android",
    value: "sigForeignMobButton",
},
{
    text: "GGO",
    value: "sigGGOButton",
},
{
    text: "GGO 2016 Outlook",
    value: "sigGGOo16Button",
},

{
    text: "GGO Funkcyjna",
    value: "sigGGOFunkcyjnaButton",
},
{
    text: "GGO  Mac",
    value: "sigGGOOSButton",
},
{
    text: "GGO IOS/Android",
    value: "sigGGOMobButton",
},
{
    text: "EWWP",
    value: "sigEWWPButton",
},
{
    text: "EWWP 2016 Outlook",
    value: "sigEWWPo16Button",
},
{
    text: "EWWP Mac",
    value: "sigEWWPOSButton",
},
{
    text: "EWWP IOS/Android",
    value: "sigEWWPMobButton",
},
{
    text: "EWWP Funkcyjna",
    value: "sigEWWPFunkcyjnaButton",
},
{
    text: "Foreign UA Osobista",
    value: "sigForeignUaButton",
},
{
    text: "Foreign UA Mobile",
    value: "sigForeignUaMobButton",
},
{
    text: "Foreign Diamond",
    value: "sigForeignDiamondButton",
},
{
    text: "Kobalt Osobista",
    value: "sigKobaltOsobistaButton",
},
{
    text: "Kobalt Funkcyjna",
    value: "sigKobaltFunkcyjnaButton",
},
{
    text: "Motivated Osobista",
    value: "sigMotivatedtOsobistaButton",
},
{
    text: "Motivated Windows",
    value: "sigMotivatedtOsobistaWinButton",
},
];

const $ = jQuery;

// **********************************************************************************
//        REGEX
var tagBody = "(?:[^\"'>]|\"[^\"]*\"|'[^']*')*";
var tagOrComment = new RegExp(
    "<(?:" +
    // Comment body.
    "!--(?:(?:-*[^->])*--+|-?)" +
    // Special "raw text" elements whose content should be elided.
    "|script\\b" +
    tagBody +
    ">[\\s\\S]*?</script\\s*" +
    "|style\\b" +
    tagBody +
    ">[\\s\\S]*?</style\\s*" +
    // Regular name
    "|/?[a-z]" +
    tagBody +
    ")>",
    "gi"
);

var invalidChars = [
    "+",
    ".",
    ",",
    "!",
    " ",
    "?",
    "(",
    ")",
    ";",
    "}",
    "{",
    ";",
    ":",
    "@",
    "$",
    "%",
    "&",
    "'",
    "=",
    "$",
];

// **********************************************************************************
//                           FUNCTIONS

// Fucntion to select ranges and copy signature by button
function selectText(elementId) {
    const node = document.getElementById(elementId);
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
    var ok = document.execCommand("copy");
    if (ok) {
        $(`.select.${elementId}`).text("Skopiowano");

        $(`.select.${elementId}`).css("background", "green");
        selection.removeAllRanges();
    }
}

// Main Fucntion to wait until page is loaded

$(document).ready(function () {
    $(".controls > .select").click(function () {
        var signatureId = $(this).parent().data("sig");
        selectText(signatureId);
        setTimeout(() => {
            ReffreshButton(signatureId);
        }, 1000);
    });

    // Fucntion button Save HTML
    $(".controls > .save").click(function () {
        var link = $(this).siblings("a")[0];

        var sig_div = $("#" + $(this).parents(".controls").data("sig"));
        var sig_html = $(sig_div).html();

        //$(link).attr("href", "data:text/html, " + sig_html + ";charset=utf-8,");
        $(link).attr("href", "data:text/html,  " + encodeURIComponent(sig_html));
        $(this).hide();
        $(link).show();
    });

    //New funciton for join positions in ENG

    // Main function with assignig input to values
    //************************************************************ */

    $("#inputs input").on("keyup change", function (e) {
        var input = $(this).attr("id");
        var val = $(this).val();

        // safe function, sanitization of tags

        val = removeTags(val);

        //Checking for erros in inputs
        /*   if (!isNaN($("#name").val()) || !isNaN($("#surname").val())) {
          errorMessage("Imię/Nazwisko nie może zawierać cyfr");
          e.preventDefault();
        } else {
          errorMessage("");
        }
        if ($(this).val().length >= 25) {
          errorMessage("Za długi ciąg znaków");
          e.preventDefault();
        } else {
          errorMessage("");
        }
        */
        $("." + input).html(val);

        if (input === "e-mail") {
            if (
                SigChooseIndex === "sigMotivatedOsobistaWin") {
                val = val.toLocaleUpperCase();
                $(".e-mail").each(function () {
                    $(this).text(val);
                    $(this).attr("href", "mailto:" + val);
                });
            }
            else $(".e-mail").each(function () {
                $(this).text(val);
                $(this).attr("href", "mailto:" + val);
            });
        }

        if (input === "phone") {
            $(".phone").each(function () {
                var optionsPhoneCode = document.getElementById("code");
                var selectPhoneCode =
                    optionsPhoneCode.options[optionsPhoneCode.selectedIndex].value;

                var phone = $("#phone").val();
                if (isNaN($("#phone").val())) {
                    // Changing content and color of content
                    errorMessage("Usuń znaki inne niż cyfry");
                }
                var PhoneTemp = phone;
                PhoneTemp = PhoneTemp.trim();
                PhoneTemp = PhoneTemp.replace(/\s/g, "");

                if (selectPhoneCode === "+48" && PhoneTemp.length >= 3) {
                    let slicePL1 = PhoneTemp.substr(0, 3);
                    let slicePL2 = PhoneTemp.substr(3, 3);
                    let slicePL3 = PhoneTemp.substr(6, 3);
                    let slicePL = slicePL1 + " " + slicePL2 + " " + slicePL3;

                    PhoneTemp = slicePL;
                }
                if (selectPhoneCode === "+38" && PhoneTemp.length >= 3) {
                    let slicePL1 = PhoneTemp.substr(0, 3);
                    let slicePL2 = PhoneTemp.substr(3, 3);
                    let slicePL3 = PhoneTemp.substr(6, 2);
                    let slicePL4 = PhoneTemp.substr(8, 2);
                    let slicePL =
                        slicePL1 + " " + slicePL2 + " " + slicePL3 + " " + slicePL4;

                    PhoneTemp = slicePL;
                }
                phone = PhoneTemp;
                let codePhone = selectPhoneCode + " " + phone;

                $(this).prop("href", "tel:" + codePhone);
                $(this).text(codePhone);
            });
        }

        $(".controls > a").hide();
        $(".controls > .save").show();
    });

    //Generate option list form Array of positions

    const selectBox = document.querySelector("#position");

    //Sort Array position
    array_position.sort((a, b) => a.value.localeCompare(b.value));

    for (const o of array_position) {
        const { text, value, texteng, selected } = o;
        selectBox.options.add(new Option(text, value, texteng, selected, selected));
    }

    //Generate option list from Array of Signatures
    const selectBoxChooseSig = document.querySelector("#choose");
    for (const o of arrayChooseSig) {
        const { text, value, selected } = o;
        selectBoxChooseSig.options.add(new Option(text, value, selected));
    }
    //Sort Array
    //arrayChooseSig.sort((a, b) => a.value.localeCompare(b.value));

    // **********************************************************************************
    $("#inputs select").on("change", function () {
        //Referesh input  val

        if (this.id === "code") {
            $(".phone").each(function () {
                var optionsPhoneCode = document.getElementById("code");
                var selectPhoneCode =
                    optionsPhoneCode.options[optionsPhoneCode.selectedIndex].value;

                var phone = $("#phone").val();
                if (isNaN($("#phone").val())) {
                    // Changing content and color of content
                    errorMessage("Usuń znaki inne niż cyfry");
                }
                var PhoneTemp = phone;
                PhoneTemp = PhoneTemp.trim();
                PhoneTemp = PhoneTemp.replace(/\s/g, "");

                if (selectPhoneCode === "+48" && PhoneTemp.length >= 3) {
                    let slicePL1 = PhoneTemp.substr(0, 3);
                    let slicePL2 = PhoneTemp.substr(3, 3);
                    let slicePL3 = PhoneTemp.substr(6, 3);
                    let slicePL = slicePL1 + " " + slicePL2 + " " + slicePL3;

                    PhoneTemp = slicePL;
                }
                if (selectPhoneCode === "+38" && PhoneTemp.length >= 3) {
                    let slicePL1 = PhoneTemp.substr(0, 3);
                    let slicePL2 = PhoneTemp.substr(3, 3);
                    let slicePL3 = PhoneTemp.substr(6, 2);
                    let slicePL4 = PhoneTemp.substr(8, 2);
                    let slicePL =
                        slicePL1 + " " + slicePL2 + " " + slicePL3 + " " + slicePL4;

                    PhoneTemp = slicePL;
                }
                phone = PhoneTemp;
                let codePhone = selectPhoneCode + " " + phone;

                $(this).prop("href", "tel:" + codePhone);
                $(this).text(codePhone);
            });
        }
    });

    $("#choose").quickChange(function (e) {
        //$(this).blur();
        $("select").on();
        SelectHideSigAndButton(this);
        SelectHideInput(this);

        let Notification = document.getElementById("notificationInsertData");
        $(Notification).hide();
    });

    $("#position").quickChange(function () {
        //doSomething

        $(".position").text($(this).val());

        var searchtext = this.options[this.selectedIndex].value;
        var indexPositionSelect = indexMatchingText(array_position, searchtext);
        $(".departament").text(array_position[indexPositionSelect].texteng);
    });
});
// END READY FUNCTIONS

// **********************************************************************************
//        Other functions

// **********************************************************************************
// Function to display error massage set by place
function errorMessage(messageERR) {
    var errorEN = document.getElementById("error1");
    errorEN.textContent = messageERR;
    errorEN.style.color = "red";
}
// **********************************************************************************
// Function to block invalid chars in input. Turn off
/*$("#inputs input").keydown(function (e) {
  //Chceck for input invalid chars

  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  } else {
  }
});*/

// Find what index select position have
function indexMatchingText(ele, text) {
    const result3 = ele.length;

    for (var i = 0; i < result3; i++) {
        if (ele[i].text === text) {
            return i;
        }
    }
    return undefined;
}

function SelectHideSigAndButton(selectDropSig) {
    var SigChooseIndex = selectDropSig.options[selectDropSig.selectedIndex].value;
    console.log(SigChooseIndex);
    let element = document.getElementById(SigChooseIndex);
    for (var i = 0; i < arrayChooseSig.length; i++) {
        var tempButton = document.getElementById(arrayChooseSigButton[i].value);
        var temp = document.getElementById(arrayChooseSig[i].value);
        if (SigChooseIndex === arrayChooseSig[i].value) {
            element.style.display = "";
            $(element).show();
            $(tempButton).show();
        } else {
            temp.style.display = "none";
            $(tempButton).hide();
        }
    }
}
/// Hide and show input to choosed Sig
function SelectHideInput(selectDropSig) {
    var SigChooseIndex = selectDropSig.options[selectDropSig.selectedIndex].value;
    let elementName = document.getElementById("nameRow");

    let elementSurnName = document.getElementById("surnameRow");
    let elementEmail = document.getElementById("e-mailRow");
    let elementEmailVal = document.getElementById("e-mail");
    let elementPosition = document.getElementById("positionRow");
    let elementPhone = document.getElementById("phoneRow");
    let elementFunc1 = document.getElementById("nameFunc1Row");
    let elementFunc2 = document.getElementById("nameFunc2Row");
    $(elementEmail).show();
    $(elementEmailVal).val("");

    $(".e-mail").val("");
    $(".e-mail").text("");

    $(".e-mail").attr("href", "mailto:");

    if (
        SigChooseIndex === "sigForeignFunkcyjna" ||
        SigChooseIndex === "sigGGOFunkcyjna" ||
        SigChooseIndex === "sigEWWPFunkcyjna" ||
        SigChooseIndex === "sigKobaltFunkcyjna"
    ) {
        $(elementName).hide();
        $(elementSurnName).hide();
        $(elementPosition).hide();
        $(elementPhone).hide();

        $(elementFunc1).show();
        $(elementFunc2).show();
    } else {
        $(elementName).show();
        $(elementSurnName).show();
        $(elementPosition).show();
        $(elementPhone).show();

        $(elementFunc1).hide();
        $(elementFunc2).hide();
    }
}

// **************************Refresh Button
function ReffreshButton(elementId) {
    $(`.select.${elementId}`).text("Kopiuj stopkę");

    $(`.select.${elementId}`).css("background", "#ff5b5b");
}

// **********************************************************************************
// Function to check for html tags in input
function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/[\d\\()*"'}{=;,\/#$% ]/gi, "");
}

$.fn.quickChange = function (handler) {
    return this.each(function () {
        var self = this;
        self.qcindex = self.selectedIndex;
        var interval;

        function handleChange() {
            if (self.selectedIndex != self.qcindex) {
                self.qcindex = self.selectedIndex;
                handler.apply(self);
            }
        }
        $(self)
            .focus(function () {
                interval = setInterval(handleChange, 100);
            })
            .blur(function () {
                window.clearInterval(interval);
            })
            .change(handleChange); //also wire the change event in case the interval technique isn't supported (chrome on android)
    });
};